const footer = "mc4c81cf40_footer";
const halfFooter = footer + " " + "mc4c81cf40_halfFooter";
const footerLight = "mc4c81cf40_footerLight";
const pageFooterNav = "mc4c81cf40_pageFooterNav";
const navChildBar = "mc4c81cf40_navChildBar";
const socialBar = "mc4c81cf40_socialBar";
const followUs = "mc4c81cf40_followUs";
const socialIcon = "mc4c81cf40_socialIcon";
const youtube = socialIcon + " " + "mc4c81cf40_youtube";
const facebook = socialIcon + " " + "mc4c81cf40_facebook";
const twitter = socialIcon + " " + "mc4c81cf40_twitter";
const twitch = socialIcon + " " + "mc4c81cf40_twitch";
const instagram = socialIcon + " " + "mc4c81cf40_instagram";
const tumblr = socialIcon + " " + "mc4c81cf40_tumblr";
const flickr = socialIcon + " " + "mc4c81cf40_flickr";
const rss = socialIcon + " " + "mc4c81cf40_rss";
const pof = "mc4c81cf40_pof";
const legalFooter = "mc4c81cf40_legalFooter";
const logos = "mc4c81cf40_logos";
const logo = "mc4c81cf40_logo";
const nc = logo + " " + "mc4c81cf40_nc";
const anet = logo + " " + "mc4c81cf40_anet";
const anetpof = anet + " " + "mc4c81cf40_anetpof";
const rating = "mc4c81cf40_rating";
const pegi = "mc4c81cf40_pegi";
const esrb = "mc4c81cf40_esrb";
const usk = "mc4c81cf40_usk";
const navCleanup = "mc4c81cf40_navCleanup";
const pageFooterNavContainer = "mc4c81cf40_pageFooterNavContainer";
export default {
    footer,
halfFooter,
footerLight,
pageFooterNav,
navChildBar,
socialBar,
followUs,
socialIcon,
youtube,
facebook,
twitter,
twitch,
instagram,
tumblr,
flickr,
rss,
pof,
legalFooter,
logos,
logo,
nc,
anet,
anetpof,
rating,
pegi,
esrb,
usk,
navCleanup,
pageFooterNavContainer
};

export {
    footer,
halfFooter,
footerLight,
pageFooterNav,
navChildBar,
socialBar,
followUs,
socialIcon,
youtube,
facebook,
twitter,
twitch,
instagram,
tumblr,
flickr,
rss,
pof,
legalFooter,
logos,
logo,
nc,
anet,
anetpof,
rating,
pegi,
esrb,
usk,
navCleanup,
pageFooterNavContainer
};