"use strict";

const m = require("mithril");

const strings = require("./strings.csv");

const css = require("./footer.css");

module.exports = {
    view(vnode) {
        const i18n = strings[vnode.attrs.lang];

        return m("div",
            {
                class       : css.rating,
                "data-test" : "esrb-rating"
            },
            m("a", {
                    class        : css.esrb,
                    href         : "https://www.esrb.org",
                    target       : "_blank",
                    "aria-label" : "esrb"
                },
                "esrb"
            ),
            m("div",
                m("ul",
                    i18n.footer.esrb.upper.split(",").map((line) => m("li", line))
                ),
                m("hr"),
                m("ul",
                    i18n.footer.esrb.lower.split(",").map((line) => m("li", line))
                )
            )
        );
    }
};
