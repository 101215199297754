"use strict";

const m = require("mithril");

const esrb = require("./esrb");
const pegi = require("./pegi");
const usk  = require("./usk");

const css = require("./footer.css");

const ga = require("../ga").event;

const strings          = require("./strings.csv");
const copyrightStrings = require("../copyright.csv");
const siteNavData      = require("../../../Code/Data/navbarsdata.json");

const ratingComponents = {
    esrb,
    pegi,
    usk
};

let _routeLink;

const a = {
    view(vnode) {
        const rl = _routeLink ? m.route.link : null;

        return m("a",
            Object.assign({ oncreate : rl, onupdate : rl }, vnode.attrs),

            vnode.children
        );
    }
};

module.exports = {
    oninit(vnode) {
        const { lang, curDate, routeLink } = vnode.attrs;

        // for the m(a) component
        _routeLink = routeLink;

        vnode.state.i18n              = strings[lang];
        vnode.state.navs              = strings[lang].nav.navlinks;
        vnode.state.followLinks       = Object.keys(strings[lang].footer.socials);
        vnode.state.copyrightWithYear = (copyrightStrings[lang] || copyrightStrings.en).copyright.replace("{year}", curDate);
    },

    view(vnode) {
        const {
            lang,
            rating,
            routeLink,
            noSocial,
            showNav,
            theme = "",
            light = false,
            footerLinks = "gw2"
        } = vnode.attrs;
        const { followLinks, i18n, navs, copyrightWithYear } = vnode.state;

        return m("div", {
                class : [
                    !showNav && !noSocial ? css.navCleanup : null,
                    !showNav && noSocial ? css.halfFooter : css.footer,
                    light ? css.footerLight : ""
                ].join(" "),
                "data-test" : "footer"
            },

            showNav || !noSocial ?
                [
                    m("ul", { class : css.pageFooterNav },
                        Object.keys(navs).map(nav =>
                            m("li",
                                m(a, {
                                        href : `${routeLink ? "" : "https://www.arena.net"}/${lang}/${navs[nav].slug}`,

                                        "data-ga-event"    : "",
                                        "data-ga-category" : "Footer"
                                    },
                                    navs[nav].name
                                )
                            )
                        ),

                        m("li",
                            m("a", {
                                    href               : i18n.nav.shoplink.slug,
                                    target             : "_blank",
                                    rel                : "noreferrer",
                                    "data-ga-event"    : "",
                                    "data-ga-category" : "Footer"
                                },
                                i18n.nav.shoplink.name
                            )
                        )
                    ),


                    // social media buttons
                    !noSocial ?
                        m("div", { class : `${css[theme]} ${css.socialBar}` },
                            m("p", { class : css.followUs }, i18n.footer.followUs),

                            followLinks.map(link =>
                                m("a", {
                                    onclick() {
                                        ga("Social Media", i18n.footer.socials[link].label, 1, "Footer");
                                    },
                                    class        : css[link],
                                    href         : siteNavData.socials[link][lang],
                                    target       : "_blank",
                                    rel          : "noreferrer",
                                    "aria-label" : i18n.footer.socials[link].label,
                                })
                            )
                        ) :
                        null
                ] :
                null,


            // Arena Logo - link to arena.net
            m("div", { class : css.logos },
                m(a, {
                        class : css.nc,
                        href  : `${routeLink ? "" : "https://www.ncsoft.com"}`,

                        "data-ga-event"    : "",
                        "data-ga-category" : "Footer",

                        "aria-label" : "NCSoft Home"
                    },
                    "NCSoft"
                ),
                m(a, {
                        class : `${css.anet} ${css[`anet${theme}`]}`,
                        href  : `${routeLink ? "" : "https://www.arena.net"}`,

                        "data-ga-event"    : "",
                        "data-ga-category" : "Footer",

                        "aria-label" : "ArenaNet Home"
                    },
                    "ArenaNet"
                )
            ),


            // legal boilerplate and links
            m("div", { class : css.legalFooter },
                m("div", // wrapper because IE11 is doodie
                    m("a",
                        {
                            href    : "#ShowCookiePreferences",
                            onclick : function(event) {
                                event.preventDefault();
                                const arenanetCookieNotification = window.arenanetCookieNotification;

                                if (arenanetCookieNotification && arenanetCookieNotification.toggle) {
                                    arenanetCookieNotification.toggle();
                                }
                            }
                        },
                        i18n.footer.links.cookiepreferences
                    ),
                    Object.entries(siteNavData.footer[footerLinks]).map(([ key, link ]) => {
                        const href  = link.replace("{lang}", lang);
                        const label = i18n.footer.links[footerLinks][key];

                        return m(link.includes("https") ? "a" : a, { href }, label);
                    })
                ),
                m("p", copyrightWithYear)
            ),


            // Ratings stamp
            rating && ratingComponents[rating] ?
                m(ratingComponents[rating], { lang, theme }) :
                null
        );
    }
};
