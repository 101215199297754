"use strict";

const m = require("mithril");

const css = require("./footer.css");

module.exports = {
    view() {
        return m("div",
            {
                class       : css.rating,
                "data-test" : "pegi-rating"
            },
            m("a", {
                    class        : css.pegi,
                    href         : "https://www.pegi.info",
                    target       : "_blank",
                    "aria-label" : "pegi"
                },
                "pegi"
            )
        );
    }
};
