"use strict";

const eventName       = "event";
const defaultCategory = "anet2";
const throttle        = 30 * 1000;

function event(eventAction, eventLabel, eventValue, eventCategory) {
    const dataLayer = window.dataLayer || [];

    dataLayer.push({
        event         : eventName || "event",
        eventCategory : eventCategory || defaultCategory,
        eventAction,
        eventLabel,
        eventValue
    });
}

// Janky work around to get click events on Nav links because m.route.link doesn't seem to accept dynamic click handlers directly?  Fn should only be used on route links.
function clickTracker() {
    const elems = document.querySelectorAll("[data-ga-event]");

    // Because IE11 doesn't support NodeList.forEach()
    Array.prototype.forEach.call(elems, (el) => {
        el.addEventListener("click", (e) => {
            event(
                e.target.dataset.gaAction || "click",
                e.target.dataset.gaLabel || e.target.text,
                e.target.dataset.gaValue || 1,
                e.target.dataset.gaCategory
            );
        });
    });
}

function pageView(url, title) {
    const dataLayer = window.dataLayer || [];

    // url = `/${state.l10n.lang}/${url}`;

    dataLayer.push({
        event : "pageview",
        url,
        title
    });
}

// View tracking (scrolling into sections)
function scrollHandler(page) {
    const elems = document.getElementsByTagName("section");

    Array.prototype.forEach.call(elems, (el) => {
        const now          = Date.now();
        const visible      = el.getBoundingClientRect().top < window.innerHeight;
        const notThrottled = !el.dataset.gaTriggered || (parseInt(el.dataset.gaTriggered, 10) + throttle < now);

        if (visible && notThrottled) {
            el.dataset.gaTriggered = now;

            event(
                "Section view",
                `${el.id.charAt(0).toUpperCase() + el.id.slice(1)}`,
                "",
                `${page} Page`
            );
        }
    });
}

module.exports = { event, clickTracker, pageView, scrollHandler };
