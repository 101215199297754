"use strict";

const m = require("mithril");

const attr = "data-mount";

const rating    = document.documentElement.getAttribute("data-rating") || "esrb";
const lang      = document.documentElement.lang.toLowerCase();
const langLinks = document.querySelectorAll("link[hreflang]"); // https://developers.google.com/search/docs/advanced/crawling/localized-versions

const langs = langLinks.length ? [] : [ "en", "de", "es", "fr" ];

const baseAttrs = {
    lang    : lang.slice(0, 2),
    langs,
    rating,
    curDate : (new Date()).getFullYear()
};

function addNode(name) {
    const dom = document.createElement("div");

    dom.setAttribute(attr, name);

    if (name === "anetdimebar") {
        document.body.prepend(dom);
    } else {
        document.body.append(dom);
    }

    return dom;
}

for (let idx = 0; idx < langLinks.length; idx++) {
    const l = langLinks[idx].getAttribute("hreflang");

    // gatsby duplicates hreflangs sometimes...
    if (!langs.includes(l)) {
        langs.push(l);
    }
}

module.exports = function mountComponents(selectedComponents) {
    selectedComponents = Array.isArray(selectedComponents) ? selectedComponents : [ selectedComponents ];

    selectedComponents.forEach(({ component, attrs = {}, name }) => {
        // mount to existing dom node, or create new
        const dom = document.querySelector(`[${attr}=${name}]`) || addNode(name);

        // ok to merge attrs outside of view, the passed in attrs cannot change at runtime
        Object.assign(attrs, baseAttrs);

        m.mount(dom, {
            view : () => m(component, attrs)
        });
    });
};
