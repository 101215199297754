"use strict";

const mountComponents = require("./components");
const anetdimebar     = require("../preload/arenanet/anet-dimebar");
const anetfooter      = require("../preload/arenanet/footer");

mountComponents([{
    name      : "anetdimebar",
    component : anetdimebar,
    attrs     : window.anetDimebarAttrs
}, {
    name      : "anetfooter",
    component : anetfooter,
    attrs     : window.anetFooterAttrs
}]);
