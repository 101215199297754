"use strict";

const m = require("mithril");

const css = require("./footer.css");

module.exports = {
    view(vnode) {
        return m("div",
            {
                class       : css.rating,
                "data-test" : "usk-rating"
            },
            m("a", {
                    class        : css.usk,
                    href         : "https://www.usk.de",
                    target       : "_blank",
                    "aria-label" : "usk"
                },
                "usk"
            )
        );
    }
};
